import React from 'react';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { st, classes } from './LoadAllButton.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';

export type LoadAllButtonProps = {
  onClick;
};
export const LoadAllButton = ({ onClick }: LoadAllButtonProps) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <TextButton
      className={st(classes.root, { isMobile })}
      onClick={onClick}
      data-hook="load-all-button"
    >
      {settings.get(settingsParams.scheduleLoadAllText)}
    </TextButton>
  );
};

import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { SchedulingAgenda } from '../BookingSchedulingAgenda';
import { classes, st } from './Scheduling.st.css';
import SectionTitle from '../SectionTitle/SectionTitle';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { SchedulingSectionViewModel } from '../../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import SectionText from '../SectionText/SectionText';
import TimezoneIndication from '../TimezoneIndication/TimezoneIndication';
import { SchedulingLocationViewModel } from '../../../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import { FloatingDropdown } from 'wix-ui-tpa/cssVars';
import { SchedulingTimezoneViewModel } from '../../../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';
import { useRenderLocation } from '../useRenderLocation';
import { useServicePageActions } from '../useServicePageActions';

export type SchedulingProps = {
  className?: string;
  schedule?: SchedulingSectionViewModel;
  locationViewModel?: SchedulingLocationViewModel;
  timezoneViewModel?: SchedulingTimezoneViewModel;
};

const CourseSummary = ({
  numberOfSessions,
  firstSessionDate,
  lastSessionDate,
  t,
}: {
  firstSessionDate?: string;
  lastSessionDate?: string;
  numberOfSessions?: number;
  t: any;
}) => {
  return (
    <div className={classes.courseSummary} data-hook="course-summary">
      <SectionText
        dataHook="scheduling-dates"
        text={t('app.scheduling.course-summary.dates', {
          firstSessionDate,
          lastSessionDate,
          interpolation: { escapeValue: false },
        })}
      />
      <SectionText
        dataHook="scheduling-number-of-sessions"
        text={t('app.scheduling.course-summary.total-number-of-sessions', {
          numberOfSessions,
        })}
      />
    </div>
  );
};

const SchedulingComponent = ({
  className,
  schedule,
  locationViewModel,
  timezoneViewModel,
}: SchedulingProps) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { changeLocationCallback, changeTimezoneCallback } = useServicePageActions();
  const location = useRenderLocation();

  const showCourseSummary =
    schedule?.lastSessionDate && schedule?.firstSessionDate;
  const getNumberOfSessions = () =>
    schedule?.schedulingDaysViewModel?.reduce(
      (total, day) => total + day.dailySessions.length,
      0,
    );

  const onLocationChanged = (locationId: string) =>
    changeLocationCallback?.(locationId);

  return (
    <div className={classes.root}>
      <SectionTitle
        title={settings.get(settingsParams.scheduleSectionTitle)}
        dataHook="scheduling-title"
        className={className}
      />
      {showCourseSummary && (
        <CourseSummary
          firstSessionDate={schedule!.firstSessionDate}
          lastSessionDate={schedule!.lastSessionDate}
          numberOfSessions={getNumberOfSessions()}
          t={t}
        />
      )}
      {locationViewModel?.locationOptions &&
        locationViewModel.locationOptions.length > 1 && (
          <FloatingDropdown
            className={st(classes.locationDropdown, { location })}
            data-hook="location-selection-dropdown"
            label=""
            options={locationViewModel.locationOptions.map((value) => ({
              ...value,
              isSelectable: true,
            }))}
            value={locationViewModel.currentLocation}
            onChange={({ id: locationId }) => onLocationChanged(locationId!)}
          />
        )}
      <SchedulingAgenda
        viewModel={schedule}
        currentLocation={locationViewModel?.currentLocation}
        key={schedule?.status}
      />
      {timezoneViewModel?.viewTimezone && (
        <TimezoneIndication
          changeTimezoneCallback={changeTimezoneCallback!}
          timezoneViewModel={timezoneViewModel}
        />
      )}
    </div>
  );
};

export default SchedulingComponent;
